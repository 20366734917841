/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import { DatePicker, Modal, message } from 'antd';
import { PageTitle, Table, Button, Pagination, Dropdown, Tabs } from '@ui-modules';
import { useNavigate } from 'react-router';
import moment from 'moment';
import { CroppedText, Tip } from '@components';
import { IDropdownOption } from '@ui-modules/types';
import { useMutation, useQuery } from 'react-query';
import { useRepository } from '@context';
import {
  ILetterDeleteParams,
  ILettersListResponse,
  Letter,
  ISignLetterResponse,
} from '@common/interfaces';
import { ReactComponent as InfoIcon } from '@assets/svg/info.svg';
import { ReactComponent as DeleteIcon } from '@assets/svg/delete.svg';
import { ReactComponent as RetryIcon } from '@assets/svg/retry.svg';
import { ReactComponent as RefreshIcon } from '@assets/svg/refresh.svg';
import './styles.scss';
import EmptyBox from '@components/EmptyBox';
import { DEFAULT_DATE_FORMAT, DEFAULT_SERVER_FORMAT } from '@common/constants';
import { Tooltip } from 'react-tooltip';
import { CalendarIcon, LetterIcon } from '@assets/svg';
import { Nullable } from '@common/interfaces/Common';
import { useSearchParams } from 'react-router-dom';

export interface IRangeDatePicker {
  from_date: string;
  to_date: string;
}

interface ILettersPage {
  type: 'award' | 'termination';
}

interface IDeleteLetterData {
  uuid: string;
  params: ILetterDeleteParams;
}

const fixOrderingParam = (param: string) => {
  if (!param.includes('provider_name')) return param;
  return param.replace('provider_name', 'provider__legal_name');
};

const { RangePicker } = DatePicker;

const tabs = ['All letters', 'Pending my signature'];

const LettersPage: FC<ILettersPage> = ({ type }) => {
  const navigate = useNavigate();
  const { freightRepository } = useRepository();
  const [searchParams] = useSearchParams();
  const defaultLetterUuid = searchParams.get('letter_uuid');

  const [selectedTab, setSelectedTab] = useState<string>(tabs[0]);
  const [selectedPage, setSelectedPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [vendorFilter, setVendorFilter] = useState<IDropdownOption | null>();
  const [contractFilter, setContractFilter] = useState<IDropdownOption | null>();
  const [datesFilter, setDatesFilter] = useState<IRangeDatePicker | null>(null);
  const [columnOrder, setColumnOrder] = useState<string>('-created');

  const [errorPdfModalData, setErrorPdfModalData] = useState<Nullable<Letter>>(null);
  const [deletingLetterModalData, setDeletingLetterModalData] = useState<Nullable<Letter>>(null);
  const [deleteLetterReason, setDeleteLetterReason] = useState('');

  const facilityId = +localStorage.getItem('facility')!;
  const country = localStorage.getItem('country');
  const isAward = type === 'award';
  const isDefaultList = selectedTab === tabs[0];
  const letterGenerateUrl = isAward ? '/letters/generate-award' : '/letters/generate-termination';

  const {
    data: listDefault,
    isLoading: isLoadingDefault,
    refetch: refetchDefaultList,
  } = useQuery<ILettersListResponse>(
    [
      'freight-letters-list',
      columnOrder,
      datesFilter,
      pageSize,
      selectedPage,
      selectedTab,
      vendorFilter,
      contractFilter,
    ],
    () =>
      freightRepository.getLettersList(facilityId, {
        from_date: datesFilter?.from_date || null,
        limit: pageSize,
        offset: (selectedPage - 1) * 10,
        ordering: fixOrderingParam(columnOrder) || null,
        provider__legal_name: vendorFilter?.value || null,
        transport_contract__short_name: contractFilter?.value || null,
        to_date: datesFilter?.to_date || null,
        letter_type: type,
      }),
  );

  const {
    data: listPending,
    isLoading: isLoadingPending,
    refetch: refetchLoadingList,
  } = useQuery<ILettersListResponse>(
    [
      'freight-pending-letters-list',
      columnOrder,
      datesFilter,
      pageSize,
      selectedPage,
      selectedTab,
      vendorFilter,
      contractFilter,
    ],
    () =>
      freightRepository.getPendingLettersList(facilityId, {
        from_date: datesFilter?.from_date || null,
        limit: pageSize,
        offset: (selectedPage - 1) * 10,
        ordering: fixOrderingParam(columnOrder) || null,
        provider__legal_name: vendorFilter?.value || null,
        transport_contract__short_name: contractFilter?.value || null,
        to_date: datesFilter?.to_date || null,
        letter_type: type,
      }),
  );

  const retryLetterGeneration = useMutation(
    (uuid: string) => freightRepository.putLetterData(uuid),
    {
      onSuccess: () => {
        message.success('PDF re-generation triggered successfully');
        window.location.reload();
      },
      onError: () => {
        message.error('An error occurred  while re-generating PDF, try to delete the letter');
      },
    },
  );

  const signLetter = useMutation(
    (uuid: string) =>
      freightRepository.signLetter(uuid, {
        return_url: `${window.location.href}?letter_uuid=${uuid}`,
      }),
    {
      onSuccess: ({ url, error }: ISignLetterResponse) => {
        if (url) window.open(url);
        if (error) message.error(error);
      },
      onError: (error) => {
        message.error(String(error));
      },
    },
  );

  const refreshLetterStatus = useMutation(
    (uuid: string) => freightRepository.refreshLetterStatus(uuid),
    {
      onSuccess: () => {
        message.success('Letter status was refreshed successfully');
        navigate(window.location.pathname);
        refetchDefaultList();
        refetchLoadingList();
      },
      onError: (error) => {
        message.error(String(error));
      },
    },
  );

  const columns = [
    {
      dataIndex: 'created',
      key: 'created',
      title: 'Generated at',
      sorter: true,
      render: (record: string) => moment(record).format(DEFAULT_DATE_FORMAT),
    },
    {
      dataIndex: 'number_of_line_items',
      key: 'number_of_line_items',
      title: 'Line items',
      sorter: true,
    },
    {
      dataIndex: 'provider_name',
      key: 'provider_name',
      title: 'Vendor name',
      sorter: true,
    },
    {
      dataIndex: 'transport_contract',
      key: 'transport_contract',
      title: 'Contract',
      render: (record: string) => <CroppedText text={record} maxLength={50} /> || '-',
    },
    {
      dataIndex: 'freight_type',
      key: 'freight_type',
      title: 'Freight type',
      render: (record: string) => record || 'n.a.',
    },
    {
      dataIndex: 'signatures',
      key: 'signatures',
      title: 'Signatures',
      render: (
        _record: string,
        {
          signatures_count,
          required_signatures_count,
          next_signer,
          should_collect_signatures,
          docusign_file_url,
        }: Letter,
      ) => {
        const isOffline = !should_collect_signatures;
        const isFull = should_collect_signatures && required_signatures_count === signatures_count;
        const isPdfInProgress = should_collect_signatures && !docusign_file_url;

        const content = isOffline ? (
          <>Signature collected offline</>
        ) : isPdfInProgress ? (
          <>Docusign PDF is being generated</>
        ) : isFull ? (
          <>All signatures collected</>
        ) : (
          <>
            Awaiting signature of:
            <br />
            <div className="bold">{next_signer}</div>
          </>
        );

        const text = isOffline
          ? 'n.a.'
          : isPdfInProgress
          ? '-/-'
          : `${signatures_count}/${required_signatures_count}`;

        return (
          <div
            className={`letter-signatures ${isOffline || isPdfInProgress ? 'offline' : ''} ${
              isFull ? 'full' : ''
            }`}
          >
            <div className="signatures-text">{text}</div>
            <Tip content={content} className="signatures-tooltip">
              <InfoIcon />
            </Tip>
          </div>
        );
      },
      width: 120,
    },
    ...(isDefaultList
      ? [
          {
            dataIndex: 'file_url',
            key: 'file_url',
            width: 100,
            render: (_record: string, item: Letter) => {
              const {
                file_url,
                is_pdf_generation_error,
                docusign_file_url,
                should_collect_signatures,
              } = item;

              return (
                <div className="button-td-wrapper">
                  {should_collect_signatures ? (
                    <Tip isVisible={!docusign_file_url} text="Docusign PDF is being generated">
                      <div>
                        <Button
                          disabled={!docusign_file_url}
                          onClick={() => window.open(docusign_file_url, '_blank')}
                          text="Open"
                        />
                      </div>
                    </Tip>
                  ) : is_pdf_generation_error ? (
                    <Button
                      onClick={() => setErrorPdfModalData(item)}
                      className="button-error-pdf"
                      text="Error"
                      icon={<InfoIcon />}
                    />
                  ) : (
                    <Tip isVisible={!file_url} text="PDF is being generated">
                      <div>
                        <Button
                          onClick={() => window.open(file_url, '_blank')}
                          text="Open"
                          disabled={!file_url}
                        />
                      </div>
                    </Tip>
                  )}
                </div>
              );
            },
          },
        ]
      : [
          {
            dataIndex: 'uuid',
            key: 'uuid',
            title: '',
            width: 100,
            render: (record: string) => (
              <Button
                className="button-sign"
                text="Sign"
                onClick={() => signLetter.mutate(record)}
              />
            ),
          },
        ]),
    {
      dataIndex: 'action',
      key: 'action',
      title: '',
      width: 32,
      render: (_record: string, item: Letter) => {
        const { should_collect_signatures, required_signatures_count, signatures_count } = item;
        const isFull = should_collect_signatures && required_signatures_count === signatures_count;
        return (
          <Tip
            text={isFull ? 'It is not possible to delete fully e-signed letters' : 'Delete letter'}
          >
            <Button
              variant="icon"
              icon={<DeleteIcon className="letter-action-icon" />}
              onClick={() => setDeletingLetterModalData(item)}
              disabled={isFull}
            />
          </Tip>
        );
      },
    },
    {
      dataIndex: 'refresh',
      key: 'refresh',
      title: '',
      width: 32,
      render: (_record: string, item: Letter) => {
        const { uuid, envelope_status } = item;
        if (envelope_status !== 'sent') return null;
        return (
          <Tip text="Refresh letter status">
            <Button
              variant="icon"
              icon={<RefreshIcon className="letter-action-icon" />}
              onClick={() => refreshLetterStatus.mutate(uuid)}
            />
          </Tip>
        );
      },
    },
  ];

  const resetFilter = () => {
    setVendorFilter(null);
    setContractFilter(null);
    setDatesFilter(null);
  };

  const resetDeletingLetterData = () => {
    setDeletingLetterModalData(null);
    setDeleteLetterReason('');
  };

  const deleteLetter = useMutation(
    (data: IDeleteLetterData) => freightRepository.deleteLetterData(data.uuid, data.params),
    {
      onSuccess: () => {
        message.success('Letter was deleted!');
        window.location.reload();
      },
      onError: (error) => {
        message.error(String(error));
      },
      onSettled: () => {
        resetDeletingLetterData();
      },
    },
  );

  useEffect(() => {
    setSelectedPage(1);
    resetFilter();
  }, [selectedTab]);

  useEffect(() => {
    if (defaultLetterUuid) {
      setSelectedTab(tabs[1]);
      refreshLetterStatus.mutate(defaultLetterUuid);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultLetterUuid]);

  const isLoading =
    (isDefaultList ? isLoadingDefault : isLoadingPending) ||
    signLetter.isLoading ||
    refreshLetterStatus.isLoading;

  const list = isDefaultList ? listDefault : listPending;

  return (
    <section className="hbh-container letters-list">
      <PageTitle
        className="section-title"
        title={`${isAward ? 'Award' : 'Termination'} letters - ${country}`}
      />
      <div className="total-info">
        Letters generated: {listDefault?.number_of_letters} | Letter status is updated every 2
        minutes
      </div>
      <Tabs
        className="tabs"
        tabs={tabs}
        selectedTab={tabs.indexOf(selectedTab)}
        onTabSelect={setSelectedTab}
        key={selectedTab}
      />
      <section className="filters">
        <Dropdown
          className="filter dropdown-filter outline"
          label={<p className="hbh-select-label">Filter by vendor</p>}
          value={vendorFilter}
          placeholder="All vendors"
          options={listDefault?.options.provider_names}
          onChange={(value) => {
            setSelectedPage(1);
            setVendorFilter(value);
          }}
        />
        <div className="dates-filter">
          <span className="hbh-select-label">Filter by date of generation</span>
          <RangePicker
            format={DEFAULT_DATE_FORMAT}
            value={
              datesFilter ? [moment(datesFilter.from_date), moment(datesFilter.to_date)] : undefined
            }
            onChange={(value) => {
              setSelectedPage(1);
              if (value === null) return setDatesFilter(null);
              return setDatesFilter({
                from_date: value[0]?.format(DEFAULT_SERVER_FORMAT) || '',
                to_date: value[1]?.format(DEFAULT_SERVER_FORMAT) || '',
              });
            }}
            disabledDate={(current) => current && current > moment().endOf('day')}
            separator="to"
            suffixIcon={<CalendarIcon className="calendar-icon" />}
          />
        </div>
        <Dropdown
          className="filter dropdown-filter outline"
          label={<p className="hbh-select-label">Filter by contract</p>}
          value={contractFilter}
          placeholder="All contracts"
          options={listDefault?.options.transport_contracts}
          onChange={(value) => {
            setSelectedPage(1);
            setContractFilter(value);
          }}
        />
        <span onClick={resetFilter} className="reset-button">
          Reset filters
        </span>
        <Button
          onClick={() => navigate(letterGenerateUrl)}
          icon={<LetterIcon />}
          text="GENERATE NEW LETTER"
          className="generate-button"
          variant="submit"
        />
      </section>
      <div className="table letters-table table-scrolling">
        <Table
          columns={columns}
          data={list?.results || []}
          rowKey="uuid"
          loading={isLoading || deleteLetter.isLoading}
          variant="dark"
          onChangeColumnOrder={setColumnOrder}
          locale={{
            emptyText: <EmptyBox />,
          }}
        />
        <Pagination
          className="pagination"
          selectedPage={selectedPage}
          showJumper
          showPageSize
          totalPages={list?.count || 0}
          variant="dark"
          onPageChange={setSelectedPage}
          onPageSizeChange={setPageSize}
        />
      </div>
      <Modal
        footer={null}
        destroyOnClose
        title={<div className="modal-title">Error on letter generation</div>}
        width="33%"
        centered
        visible={errorPdfModalData !== null}
        onCancel={() => setErrorPdfModalData(null)}
      >
        <div className="modal-content-text">
          <p>
            There was an error while generation the letter for
            <br />
            <b>{errorPdfModalData?.provider_name}</b>
          </p>
          <p>
            You can try generation again by clicking on <b>&apos;Retry&apos;</b>
            <br />
            or click <b>&apos;Delete letter&apos;</b> to generate a new one.
          </p>
        </div>
        <div className="modal-buttons">
          <Button
            onClick={() => {
              setErrorPdfModalData(null);
              retryLetterGeneration.mutate(errorPdfModalData?.uuid || '');
            }}
            icon={<RetryIcon />}
            text="Retry"
          />
          <Button
            onClick={() => {
              setErrorPdfModalData(null);
              deleteLetter.mutate({ uuid: errorPdfModalData?.uuid || '', params: {} });
            }}
            icon={<DeleteIcon />}
            text="Delete letter"
          />
        </div>
      </Modal>
      <Modal
        footer={null}
        destroyOnClose
        title={<div className="modal-title">Delete letter</div>}
        width="33%"
        centered
        visible={deletingLetterModalData !== null}
        onCancel={resetDeletingLetterData}
      >
        <div className="modal-content-text">
          <p>
            Do you want to delete letter for <b>{deletingLetterModalData?.provider_name}</b>{' '}
            generated on {moment(deletingLetterModalData?.created).format(DEFAULT_DATE_FORMAT)}?
          </p>
          {deletingLetterModalData?.should_collect_signatures ? (
            <>
              <p>
                A notification will be sent to the current e-signer and it will be possible to
                generate a new letter for the same line items.
              </p>
              <textarea
                value={deleteLetterReason}
                placeholder="Reason..."
                className="modal-textarea"
                onChange={(e) => setDeleteLetterReason(e.target.value)}
              />
            </>
          ) : null}
        </div>
        <div className="modal-buttons">
          <Button onClick={resetDeletingLetterData} variant="danger" text="Cancel" />
          <Tip
            isVisible={
              deletingLetterModalData?.should_collect_signatures && deleteLetterReason.length === 0
            }
            text="Please, provide the reason"
          >
            <Button
              onClick={() => {
                setDeletingLetterModalData(null);
                deleteLetter.mutate({
                  uuid: deletingLetterModalData?.uuid || '',
                  params: {
                    reason: deletingLetterModalData?.should_collect_signatures
                      ? deleteLetterReason
                      : undefined,
                  },
                });
              }}
              text="Delete letter"
              variant="submit"
              disabled={
                deletingLetterModalData?.should_collect_signatures &&
                deleteLetterReason.length === 0
              }
            />
          </Tip>
        </div>
      </Modal>
      <Tooltip id="global-tooltip" />
    </section>
  );
};

export default LettersPage;
